import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompanyService from "../../services/CompanyService";

export const getCompany = createAsyncThunk('get-all-company', async (data, { rejectWithValue }) => {
    try {
        const response = await CompanyService.getCompany();
        console.log(response)
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllCompanySize = createAsyncThunk('get-all-company-size', async (data, { rejectWithValue }) => {
    try {
        const response = await CompanyService.getAllCompanySize();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

const CompanySlice = createSlice({
    name: 'company',
    initialState: {
        company: [],
        companySize: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getCompany.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.loading = false
                state.company = action.payload;
            })
            .addCase(getCompany.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllCompanySize.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllCompanySize.fulfilled, (state, action) => {
                state.loading = false
                state.companySize = action.payload;
            })
            .addCase(getAllCompanySize.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default CompanySlice.reducer;
