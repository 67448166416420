import fetch from "../auth/FetchInterceptor";

const CompanyService = {}

CompanyService.getCompany = function () {
    return fetch({
        url: '/Company/get-all-company',
        method: 'get',
    });
};

CompanyService.getAllCompanySize = function () {
    return fetch({
        url: '/CompanySize/get-all-company-size',
        method: 'get',
    });
};

export default CompanyService;
